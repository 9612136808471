// import { useUpdates } from "../core/sync";

export interface Topic {
    title: string
    color: string
}

// const hubName = 'topics'
// const handleChanges: (oldState: Topic[] | undefined, event: any, ...args: any[]) => Topic[] = (oldState: Topic[] | undefined, event: any, ...args: any[]) => {
//     console.log(oldState, event, args)
//     switch (event) {
//         case `${hubName}.add`: {
//             return [...oldState ?? [], args[0].added as Topic];
//         }
//     }
//     return oldState ?? []
// }
export const useTopics: () => [Topic[], (topic: string) => void] = () => {
    // const [topics, , con] = useUpdates<Topic[]>(hubName, handleChanges)
    // const addTopic = (topic: string) => con.send('add', { title: topic })
    // return [topics ?? [], addTopic]

    var stringToColour = function (str: string) {
        var hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '#';
        for (let i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }

    return [["Бухгалтерский учет",
        "Налогообложение",
        "Бизнес и финансы",
        "Продуктовая аналитика",
        "Data Science",
        "Маркетинг, Реклама и PR",
        "SMM и продвижение в социальных сетях",
        "Правовые и юридические вопросы",
        "Иностранные языки",
        "Психология",
        "Школьное образование",
        "Карьера в IT",
        "Разработка сайтов и приложений",
        "Архитектура ПО",
        "Дизайн",
        "Строительство и ремонт",
        "Недвижимость и риелторские услуги",
        "Другое",].map(x => ({ title: x, color: stringToColour(x) })), () => { }]
}

