import { useContext, createContext, useState } from "react";
import {
  Route,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

interface Auth {
  isAuthenticated: boolean
  user: string | null
  role: string
  signin: () => Promise<void>
  signout: () => Promise<void>
};

export const AuthContext = createContext<Auth | null>(null);

export function ProvideAuth({ children }: { children: any}) {
  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export function useProvideAuth() {
  const [user, setUser] = useState<string|null>(null);
  const [role, setRole] = useState<string>("guest");
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const signin = async () => {
    setUser("user");
    setIsAuthenticated(true);
    setRole("user")
  };

  const signout = async () => {
    setUser(null);
    setIsAuthenticated(false);
    setRole("guest")
  };

  return {
    isAuthenticated,
    user,
    role,
    signin,
    signout
  };
}

export function AuthButton() {
  let history = useHistory();
  let auth = useAuth();

  return auth?.user ? (
    <p>
      Welcome!{" "}
      <button
        onClick={async () => {
          await auth?.signout();
          history.push("/");
        }}
      >
        Sign out
      </button>
    </p>
  ) : (
    <p>You are not logged in.</p>
  );
}
 
export function PrivateRoutes({ children, ...rest }: {children:any}) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth?.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export function PublicPage() {
  return <h3>Public</h3>;
}

export function ProtectedPage() {
  return <h3>Protected</h3>;
}

export function LoginPage() {
  let history = useHistory();
  let location = useLocation<{from: {pathname: string}}>();
  let auth = useAuth();

  let { from } = location.state || { from: { pathname: "/" } };
  let login = async () => {
    await auth?.signin();
    history.replace(from)
  };

  return (
    <div>
      <p>You must log in to view the page at {from.pathname}</p>
      <button onClick={login}>Log in</button>
    </div>
  );
}
