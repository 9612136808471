import { useTopics } from "../data/topics"
import { TagCloud } from 'react-tagcloud'
import { useState } from "react"
import './onboarding.css'
import { useHistory } from 'react-router-dom';
import { useAuth } from "../core/auth"

export const TopicSelection = () => {
    const [topics] = useTopics()
    const history = useHistory()

    const selectTag = (tag:string) => {
        history.push('/ask-question')
    }
    return <div>
        <h1>Выберите тему</h1>
        <TagCloud
            minSize={12}
            maxSize={35}
            className={"tag-cloud"}
            tags={topics.map(t => ({ value: t.title, count: 1, color: t.color }))}
            onClick={(tag: any) => selectTag(tag.value)}
        />
    </div>
}

export const RegistrationAndQuestion = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordDub, setPasswordDub] = useState("")
    const [question, setQuestion] = useState("")
    const history = useHistory()
    const auth = useAuth()

    const register = async () => {
        await auth?.signin()
        history.push("/")
    }
    return <div>
        <h1>Задайте вопрос</h1>
        Имя<input type="text" value={name} onChange={(e) => setName(e.target.value)} /><br/>
        Почта<input type="email" value={email} onChange={(e) => setEmail(e.target.value)} /><br/>
        Пароль<input type="password" value={password} onChange={(e) => setPassword(e.target.value)} /><br/>
        Ещё разок<input type="password" value={passwordDub} onChange={(e) => setPasswordDub(e.target.value)} /><br/>
        Ваш вопрос<textarea value={question} onChange={(e) => setQuestion(e.target.value)}/>
        <button onClick={register}>Задать вопрос</button>
    </div>
}