import { Link } from "react-router-dom"

export const UserProfile = () => {
    const questions = [
        { id: "1", question: "Some very specific, quite clear, pretty long, mildly important question that need to be long enough for be a multi-liner", topic: "Животноводство", requests: [], createdAt: new Date() },
        { id: "2", question: "Some very specific, quite clear, pretty long, mildly important question that need to be long enough for be a multi-liner", topic: "Животноводство", requests: [], createdAt: new Date() },
        { id: "3", question: "Some very specific, quite clear, pretty long, mildly important question that need to be long enough for be a multi-liner", topic: "Животноводство", requests: [], createdAt: new Date() },
        { id: "4", question: "Some very specific, quite clear, pretty long, mildly important question that need to be long enough for be a multi-liner", topic: "Животноводство", requests: [], createdAt: new Date() },
        {
            id: "5", question: "Some very specific, quite clear, pretty long, mildly important question that need to be long enough for be a multi-liner", topic: "Животноводство", requests: [
                {
                    id: "1",
                    user: "derp",
                    comment: "test",
                    createdAt: new Date()
                },
                {
                    id: "2",
                    user: "derp",
                    comment: "test",
                    createdAt: new Date()
                }
            ], createdAt: new Date()
        },
        { id: "6", question: "Some very specific, quite clear, pretty long, mildly important question that need to be long enough for be a multi-liner", topic: "Животноводство", requests: [], createdAt: new Date() },
    ]
    return <div>
        <ul>
            {questions.map(q => <li key={q.id}>
                <h3>
                    {q.question}
                </h3>
                <span className="info">Задан: {q.createdAt.toDateString()}</span>
                <span className="info"> | Тема: {q.topic}</span>
                {q.requests.length === 0 && <div>Для данного вопроса пока нет отзывов от экспертов, <a>подпишитесь на оповещения</a></div>}
                {q.requests.length !== 0 && <ul>
                    {q.requests.map(a => <li key={a.id}><div>{a.comment}</div><span className="info">Комментарий от {a.user} {a.createdAt.toDateString()}</span>
                        <Link to={`/chat/${q.id}/${a.user}`}>Перейти к разговору</Link>
                    <br/></li>)}
                </ul>}
                <br/>
            </li>)}
        </ul>
    </div>
}