import './App.css';
import { Switch, Route, Link, Redirect, useLocation, useHistory } from 'react-router-dom';
import { AdminSettings } from './pages/admin';
import { RegistrationAndQuestion, TopicSelection } from './pages/onboarding';
import { useAuth } from './core/auth';
import { UserProfile } from './pages/user-profile';
import { config } from './config';
import { Chat } from './pages/chat';

const UserInfo = ({ showPoints }: { showPoints: boolean }) => {
  const auth = useAuth()

  return <div>
    {auth?.user}
    {showPoints && "100 points"}
  </div>
}
function App() {
  const location = useLocation()
  const history = useHistory()
  const auth = useAuth()
  const logout = async ()=> {
    await auth?.signout();
    history.push('/')
  }
  return <div>
    {!auth?.isAuthenticated &&
      <div>
        <header>
          <Link to={"/login"} title="Войти" className="login">Войти</Link>
          <Link to={"/register-as-expert"} title="Хочу быть экспертом" className="register-as-expert">Хочу быть экспертом</Link>
          {location.pathname !== '/' && <Link to={"/"} title="Задать вопрос" className="select-topic">Задать вопрос</Link>}
        </header>
        <Switch >
          <Route path="/register-as-expert">Register as expert</Route>
          <Route path="/login">Login</Route>
          <Route path="/ask-question"><RegistrationAndQuestion /></Route>
          <Route path="/"><TopicSelection /></Route>
        </Switch>
      </div>
    }
    {auth?.role === "user" &&
      <div>
        <header>
        <a onClick={logout} href="/"  title="Выйти">Выйти</a>
          <UserInfo showPoints={true} />
        </header>
        <Switch>
          <Route path="/chat/:questionId/:user"><Chat/></Route>
          <Route path="/"><UserProfile/></Route>
        </Switch>
      </div>
    }
    {auth?.role === "expert" &&
      <div>
        <header>
        <a onClick={logout} href="/" title="Выйти">Выйти</a>
          <UserInfo showPoints={true} />
        </header>
        <Switch>
          <Route path="/chat/:questionId/:user"><Chat/></Route>
          <Route path="/">Expert profile</Route>
        </Switch>
      </div>
    }
    {auth?.role === "admin" &&
      <div>
        <header>
          <a onClick={logout} href="/" title="Выйти">Выйти</a>
          <UserInfo showPoints={true} />
        </header>
        <Switch>
          <Route path="/"><Redirect to="/admin" /></Route>
          <Route path="/admin"><AdminSettings /></Route>
        </Switch>
      </div>
    }

    <footer>{config.companyName} © {config.year} u are: {auth?.user} {auth?.role} {auth?.isAuthenticated}</footer>
  </div>
}

export default App;
