import { useState } from "react"
import { useTopics } from "./../data/topics"


export const AdminSettings = () => {
    const [topics, addTopic] = useTopics()
    const [newTopic, setNewTopic] = useState("")
    return <div>
        <input type="text" value={newTopic} onChange={(e) => setNewTopic(e.target.value)} /><button onClick={() => addTopic(newTopic)}>Add topic</button>
        <ul>
            {topics.map(x => <li key={x.title}>{x.title}</li>)}
        </ul>
    </div>
}