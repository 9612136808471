import { ChatFeed, Message } from 'react-chat-ui'

export const Chat = () => {
    const messages: Message[] = [
        {id: "1", message: "Привет!", senderName: "user" },
        {id: "2", message: "Как дила", senderName: "user2" },
    ]
    return <ChatFeed
        messages={messages} // Array: list of message objects
        isTyping={true} // Boolean: is the recipient typing
        hasInputField={false} // Boolean: use our input, or use your own
        showSenderName // show the name of the user who sent the message
        bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
        // JSON: Custom bubble styles
        bubbleStyles={
            {
                text: {
                    color: 'black',
                    fontSize: 25
                },
                chatbubble: {
                    borderRadius: 7,
                    padding: 4
                }
            }
        }
    />
}